import React from 'react';
import styled from 'styled-components';
import { minBreakpointQuery, sectionMargins, headingStyles } from '../styles';
import { AnimatedCircles, CallbackButton } from './ui';
import { translateString } from '../utils';

const StyledCallbackCta = styled.section`
  ${sectionMargins()};
  overflow: hidden;
`;

const StyledInner = styled.div`
  position: relative;
  margin: auto;
  padding: 30px;
  max-width: 840px;

  ${minBreakpointQuery.tiny`
    padding: 60px;
  `}

  ${minBreakpointQuery.small`
    padding: 90px;
  `}

  ${minBreakpointQuery.medium`
    padding: 120px;
  `}
`;

const StyledContent = styled.div`
  position: relative;
  text-align: center;
`;

const StyledHeading = styled.h2`
  margin-bottom: 20px;
  ${headingStyles('40px', '70px')};

  ${minBreakpointQuery.small`
    margin-bottom: 26px;
  `}
`;

const CallbackCta = ({ heading, locale }) => (
  <StyledCallbackCta>
    <StyledInner>
      <AnimatedCircles />
      <StyledContent>
        <StyledHeading>
          {heading ||
            `${translateString('Are you ready to get started', locale)}?`}
        </StyledHeading>
        <CallbackButton arrow={true} locale={locale} />
      </StyledContent>
    </StyledInner>
  </StyledCallbackCta>
);

export default CallbackCta;

import React from 'react';
import { navigate, graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';
import {
  minBreakpointQuery,
  maxBreakpointQuery,
  standardColours,
  brandColours,
  headingStyles,
} from '../styles';
import { InViewAnimation, Overline, Button } from './ui';
import { buildUrl, translateString } from '../utils';

const StyledCaseStudyCard = styled.article`
  position: relative;
  cursor: pointer;

  ${minBreakpointQuery.small`
    display: flex;
    align-items: center;
    margin-top: 80px;
  `}

  &:before {
    ${minBreakpointQuery.small`
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 75%;
      background-color: ${brandColours.primary};
    `}
  }
`;

const StyledImage = styled(Img)`
  ${maxBreakpointQuery.small`

    @supports (aspect-ratio: 8 / 5) {
      aspect-ratio: 8 / 5;
    }

    @supports not (aspect-ratio: 8 / 5) {
      max-height: 220px;
    }
  `}

  ${minBreakpointQuery.small`
    bottom: 80px;
    width: 100%;
    max-width: 480px;

    @supports (aspect-ratio: 4 / 5) {
      aspect-ratio: 4 / 5;
    }
  `}

  ${minBreakpointQuery.smedium`

    @supports (aspect-ratio: 1 / 1) {
      aspect-ratio: 1 / 1;
    }
  `}
`;

const StyledContent = styled.div`
  position: relative;
  padding: 40px 30px;
  background-color: ${brandColours.primary};

  ${minBreakpointQuery.tiny`
    padding: 50px 40px;
  `}

  ${minBreakpointQuery.small`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-left: auto;
    max-width: 880px;
  `}

  ${minBreakpointQuery.medium`
    padding: 60px;
  `}

  ${minBreakpointQuery.large`
    padding: 80px;
  `}

  ${minBreakpointQuery.xxlarge`
    padding: 100px;
  `}
`;

const StyledOverline = styled(Overline)``;

const StyledHeading = styled.h3`
  color: ${standardColours.white};
  ${headingStyles('26px', '35px')};
`;

const StyledButton = styled(Button)`
  margin-top: 18px;
  color: inherit;
  background-color: ${standardColours.white};

  ${minBreakpointQuery.medium`
    margin-top: 22px;
  `}

  &:after {
    border-color: inherit;
  }

  &:hover {
    color: ${standardColours.white};

    &:after {
      border-color: ${standardColours.white};
    }
  }
`;

const CaseStudyCard = ({
  overline,
  heading,
  link,
  image: { fluid, alt },
  locale,
  flip,
  isFeatured,
  ...props
}) => (
  <StyledCaseStudyCard
    {...props}
    onClick={() => {
      navigate(buildUrl(link.slug, link));
    }}
  >
    <StyledImage fluid={fluid} alt={alt} />
    <StyledContent>
      <InViewAnimation>
        <StyledOverline>
          {overline || translateString('Case Study', locale)}
        </StyledOverline>
        <StyledHeading>{heading}</StyledHeading>
        <StyledButton to={link} arrow={true}>
          {translateString('View case study', locale)}
        </StyledButton>
      </InViewAnimation>
    </StyledContent>
  </StyledCaseStudyCard>
);

export default CaseStudyCard;

export const CaseStudyCardFragment = graphql`
  fragment CaseStudyCardFragment on DatoCmsCaseStudy {
    id
    locale
    internal {
      type
    }
    title
    slug
    featuredImage {
      fluid(
        maxHeight: 480
        maxWidth: 480
        imgixParams: { auto: "compress", fit: "crop", h: "480", w: "480" }
      ) {
        ...GatsbyDatoCmsFluid
      }
      alt
    }
  }
`;

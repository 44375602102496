import React from 'react';
import styled, { css } from 'styled-components';
import { minBreakpointQuery, gridBackground } from '../styles';
import { Container } from './ui';
import { translateString } from '../utils';
import CaseStudyCard from './CaseStudyCard';

const StyledCaseStudyListing = styled.section`
  ${gridBackground()};
`;

const StyledItem = styled(CaseStudyCard)`
  margin: 30px 0;

  ${minBreakpointQuery.small`
    margin-top: 100px;
    margin-bottom: 100px;
  `}

  ${minBreakpointQuery.smedium`
    margin-top: 140px;
    margin-bottom: 140px;
  `}

  ${minBreakpointQuery.mlarge`
    margin-top: 180px;
    margin-bottom: 180px;
  `}

  ${minBreakpointQuery.xxxlarge`
    margin-top: 220px;
    margin-bottom: 220px;
  `}

  &:first-child {
    ${minBreakpointQuery.small`
      margin-top: 80px;
    `}

    ${minBreakpointQuery.smedium`
      margin-top: 100px;
    `}
  }

  ${({ flip }) => {
    if (flip) {
      return css`
        ${minBreakpointQuery.small`
          flex-direction: row-reverse;
        `}

        &:before {
          ${minBreakpointQuery.small`
            left: 0;
          `}
        }
      `;
    }
  }}
`;

const CaseStudyListing = ({ items, locale }) =>
  items.length > 0 && (
    <StyledCaseStudyListing>
      <Container>
        {items.map((item, i) => (
          <StyledItem
            key={item.id}
            overline={translateString('Case Study', locale)}
            heading={item.title}
            link={item}
            image={item.featuredImage}
            locale={locale}
            flip={i % 2}
          />
        ))}
      </Container>
    </StyledCaseStudyListing>
  );

export default CaseStudyListing;

import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import Banner from '../components/Banner';
import CaseStudyListing from '../components/CaseStudyListing';
import CallbackCta from '../components/CallbackCta';
import { getCollectionLocaleFields, translateString } from '../utils';

const CaseStudiesPageTemplate = ({
  data: { datoCmsCaseStudiesArchive, allDatoCmsCaseStudy },
  pageContext: { locale },
}) => {
  const { seoMetaTags, slugLocales, title, subtitle } =
    datoCmsCaseStudiesArchive;

  const { nodes } = getCollectionLocaleFields(allDatoCmsCaseStudy, locale);

  return (
    <Layout
      seo={seoMetaTags}
      locale={locale}
      slugLocales={slugLocales}
      page={datoCmsCaseStudiesArchive}
      verticalText={translateString('Case Studies', locale)}
    >
      <main>
        <Banner
          overline={translateString('Case Studies', locale)}
          heading={title}
          text={subtitle}
          locale={locale}
        />
        <CaseStudyListing items={nodes} locale={locale} />
      </main>
      <CallbackCta locale={locale} />
    </Layout>
  );
};

export const CaseStudiesPageTemplateQuery = graphql`
  query CaseStudiesPageTemplateQuery($id: String!) {
    datoCmsCaseStudiesArchive(id: { eq: $id }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      slugLocales: _allSlugLocales {
        locale
        value
      }
      title
      subtitle
      ...LinkFragment
    }
    allDatoCmsCaseStudy(
      filter: { slug: { ne: null } }
      sort: { fields: position }
    ) {
      group(field: locale) {
        fieldValue
        nodes {
          ...CaseStudyCardFragment
        }
      }
    }
  }
`;

export default CaseStudiesPageTemplate;
